import { Injectable, inject } from '@angular/core';

import { Observable, of, shareReplay, switchMap } from 'rxjs';

import { filterNull } from '../utils/rxjs/filter-null';

import { QuoteDetail } from '../models/quote/quote-detail';

import { UserService } from './user.service';
import { PermissionApiService } from './permission-api.service';

/** Permission Service. */
@Injectable({
	providedIn: 'root',
})
export class PermissionService {
	private readonly userService = inject(UserService);

	private readonly permissionApiService = inject(PermissionApiService);

	/** Can user approve quote. */
	public readonly canApproveQuote$: Observable<boolean>;

	/** Can user create quote. */
	public readonly canCreateQuote$: Observable<boolean>;

	/** Whether the current user can edit users. */
	public readonly canEditUsers$: Observable<boolean>;

	/** Whether the current user can delete equipment. */
	public readonly canDeleteEquipment$: Observable<boolean>;

	/** Whether the current user can delete rental equipment. */
	public readonly canDeleteRentalEquipment$: Observable<boolean>;

	public constructor() {
		this.canApproveQuote$ = this.userService.currentUser$.pipe(
			filterNull(),
			switchMap(user => this.permissionApiService.canApproveQuote(user.id)),
			shareReplay({ bufferSize: 1, refCount: true }),
		);

		this.canCreateQuote$ = this.userService.currentUser$.pipe(
			filterNull(),
			switchMap(user => this.permissionApiService.canCreateQuote(user.id)),
			shareReplay({ bufferSize: 1, refCount: true }),
		);

		this.canDeleteEquipment$ = this.userService.currentUser$.pipe(
			filterNull(),
			switchMap(user => this.permissionApiService.canDeleteEquipment(user.id)),
			shareReplay({ bufferSize: 1, refCount: true }),
		);

		this.canDeleteRentalEquipment$ = this.userService.currentUser$.pipe(
			filterNull(),
			switchMap(user => this.permissionApiService.canDeleteRentalEquipment(user.id)),
			shareReplay({ bufferSize: 1, refCount: true }),
		);

		this.canEditUsers$ = this.userService.currentUser$.pipe(
			switchMap(user => {
				if (user == null) {
					return of(false);
				}
				return this.permissionApiService.canEditUsers(user.id);
			}),
		);
	}

	/**
	 * Can user edit quote.
	 * @param quoteId Quote ID.
	 */
	public canEditQuote(quoteId: QuoteDetail['id']): Observable<boolean> {
		return this.userService.currentUser$.pipe(
			filterNull(),
			switchMap(user => this.permissionApiService.canEditQuote(user.id, quoteId)),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
	}

	/**
	 * Can user view quote.
	 * @param quoteId Quote ID.
	 */
	public canViewQuote(quoteId: QuoteDetail['id']): Observable<boolean> {
		return this.userService.currentUser$.pipe(
			filterNull(),
			switchMap(user => this.permissionApiService.canViewQuote(user.id, quoteId)),
			shareReplay({ bufferSize: 1, refCount: true }),
		);
	}
}
