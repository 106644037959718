/** Base filter parameters. */
export namespace BaseFilterParams {

	/** Search filter. */
	export type Search = {

		/** Search filter. */
		readonly search: string;
	};

	/** Pagination filters. */
	export type Pagination = {

		/** Page number filter. */
		readonly pageNumber: number;

		/** Page size filter. */
		readonly pageSize: number;
	};

	/** Search and pagination filters. */
	export type Combined = Search & Pagination;
}

/** Default pagination. */
export const DEFAULT_PAGINATION: BaseFilterParams.Pagination = {
	pageNumber: 0,
	pageSize: 20,
};

/** Default pagination params to fetch all existing records. */
export const DEFAULT_PAGINATION_FOR_ALL_RECORDS: BaseFilterParams.Pagination = {
	pageNumber: 0,
	pageSize: 10000,
};
