import { Injectable, inject } from '@angular/core';

import { Observable, map } from 'rxjs';

import { HttpClient } from '@angular/common/http';

import { JobSiteMapper } from '../mappers/job-site.mapper';
import { JobSite } from '../models/quote/job-site';

import { jobSiteDtoSchema } from '../dtos/quote/job-site.dto';

import { Location, LocationFilterParams } from '../models/location';
import { LocationMapper } from '../mappers/location.mapper';
import { DataWithWrapperDto } from '../dtos/data-with-wrapper.dto';
import { secureParse } from '../utils/secureParse';
import { locationDtoSchema } from '../dtos/location.dto';

import { composeHttpParams } from '../utils/compose-http-params';

import { AppUrlsConfig } from './app-urls.config';

/** Location service. */
@Injectable({
	providedIn: 'root',
})
export class LocationService {
	private readonly appUrlsConfig = inject(AppUrlsConfig);

	private readonly jobSiteMapper = inject(JobSiteMapper);

	private readonly locationMapper = inject(LocationMapper);

	private readonly httpClient = inject(HttpClient);

	/**
	 * Get job sites of a specific location.
	 * @param id Location ID.
	 */
	public getJobSites(id: number): Observable<JobSite[]> {
		return this.httpClient.get(this.appUrlsConfig.location.jobSites(id)).pipe(
			map(response => jobSiteDtoSchema.array().parse(response)),
			map(dtos => dtos.map(dto => this.jobSiteMapper.fromDto(dto))),
		);
	}

	/**
	 * Get all available locations.
	 * @param params Filter params.
	 */
	public getAll(params?: LocationFilterParams): Observable<Location[]> {
		const paramsDto = params ? composeHttpParams(this.locationMapper.toParamsDto(params)) : undefined;
		return this.httpClient.get<DataWithWrapperDto>(this.appUrlsConfig.location.list, { params: paramsDto }).pipe(
			map(response => secureParse(response.data, locationDtoSchema.array())),
			map(dtos => dtos.map(dto => this.locationMapper.fromDto(dto))),
		);
	}

	/**
	 * Get locations that in the same region with the given location.
	 * @param id Location ID>.
	 */
	public getSameLocations(id: Location['id']): Observable<Location[]> {
		return this.httpClient.get<DataWithWrapperDto>(this.appUrlsConfig.location.sameLocations(id)).pipe(
			map(response => secureParse(response.data, locationDtoSchema.array())),
			map(dtos => dtos.map(dto => this.locationMapper.fromDto(dto))),
		);
	}
}
